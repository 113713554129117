@font-face {
	font-family: 'Noto-Sans-Khmer';
	src: local('Noto-Sans-Khmer'), url('./fonts/Noto\ Sans\ Khmer.ttf') format('truetype');
}

@font-face {
	font-family: 'Noto-Sans-Khmer-Bold';
	src: local('Noto-Sans-Khmer-Bold'), url('./fonts/NotoSansKhmer-CondensedBold.ttf') format('truetype');
	font-weight: 700;
}

@font-face {
	font-family: 'FWD-Circular';
	src: local('FWD-Circular'), url('./fonts/FWDCircularTT-Book.ttf') format('truetype');
	font-weight: 700;
}
